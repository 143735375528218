import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Header from './components/Header';
import '../src/styles/blog.css'
import '../src/styles/PrivacyPolicy.css'
import '../src/styles/style.css'
import '../src/styles/team.css'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <App/>
  </React.StrictMode>
);

