import '../styles/style.css';
import React from 'react';
import { useEffect } from 'react';

function SoftwareDevelopment() { useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []); 
  return (
    <main>
        {/* <div style={{ paddingBottom: '65px', textAlign: 'center' }}></div> */}
      <section className="headerio">
        <h1>Software Development</h1>
        </section>
      <section className="services-item">
        <h2>Software Development Understanding</h2>
        <br />
        <p>
        At the beginning, we take our time to sit with our client
        and discuss their requirements. We then come up with a
        non-technical layout of the software development and its
        core features. This helps us clear any doubts and avoids
        errors in the development process. After understanding
        the client's requirements, company goals, vision, and
        target audience, we move to the second stage of development.
        </p>
      </section>

      <section className="services-item">
        <h2>Building</h2>
        <br />
        <p>
        This is the building phase. After understanding
        all the necessary project requirements, we use various
        software development methodologies, such as Agile or Waterfall.
        During this phase, you will receive updates and suggestions
        from our project manager, keeping you informed during the software
        application's development. The software is thoroughly
        tested to eliminate any programming bugs.
        </p>
      </section>

      <section className="services-item">
        <h2>Planning</h2>
        <br />
        <p>Following the recommendations, we create a plan of action.
        In this phase, we work on all software and hardware requirements,
        project scope and deadlines, and any fixed milestones. We keep
        you informed about every step and maintain flexibility to avoid
        unforeseen difficulties during the software development phase.</p>
      </section>

      <section className="services-item">
        <h2>Deploying</h2>
        <br />
        <p>
        At this stage, the software is not ready for end users.
        It needs to be hosted on markets and app stores before
        becoming available for use. During this phase, we take
        care of every aspect of your software that must be well-tested
        before reaching end users.
        </p>
      </section>

      <section className="services-item">
        <h2>Maintaining</h2>
        <br />
        <p>
        Now comes the maintaining phase.
        As software applications are not meant to last forever,
        upgrades will be necessary. We maintain engagement with
        you to ensure that the investment you made in software
        application development provides the best value for money,
        accommodating version upgrades, market evolution, and customer
        demands for new features.
        </p>
      </section>
    </main>
  );
}

export default SoftwareDevelopment;
