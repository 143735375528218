import '../styles/style.css';
import React from 'react';
import { useEffect } from 'react';

function Softwaresecurity() { useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []); 
  return (
    <main>
        {/* <div style={{ paddingBottom: '65px', textAlign: 'center' }}></div> */}
      <section className="headerio">
        <h1>Software Security </h1>
        </section>
      <section className="services-item">
        <h2>Software  Security Understanding</h2>
        <br />
        <p>
        With a DevSecOps expertise in secure software development and software security assurance Kanda helps
        clients to create state-of-the-art secure applications, assess and significantly increase security level of
        the existing software solutions.
        
        DevSecOps Softwares Pvt.Ltd teams have been working in Security Sector for decades helping
        companies, whose core competence is Application Security. We transfer this knowledge and best
        practices to assist clients ranging from startups to large enterprises in a variety of industries in
        ensuring the protection of their data and applications.
        </p>
      </section>

      <section className="services-item">
        <h2>DevSecOps Security Services</h2>
        <br />
        <p>
        Risk Management and Complance,Application security Teasting,Security services,
         Infrastructure security consulting, Security design and code review
        </p>
      </section>

      <section className="services-item">
        <h2>Planning</h2>
        <br />
        <p>Following the recommendations, we create a plan of action.
         In this phase, we work on all software and hardware requirements, 
         project scope and deadlines, and any fixed milestones. We keep 
         you informed about every step and maintain flexibility to avoid
          unforeseen difficulties during the software development phase.</p>
      </section>

      <section className="services-item">
        <h2>Deploying</h2>
        <br/>
        <p>
        At this stage, the software is not ready for end users.
        It needs to be hosted on markets and app stores before 
        becoming available for use. During this phase, we take
         care of every aspect of your software that must be well-tested
          before reaching end users.
        </p>
      </section>

      <section className="services-item">
        <h2>Maintaining</h2>
        <br />
        <p>
        Now comes the maintaining phase. 
        As software applications are not meant to last forever,
         upgrades will be necessary. We maintain engagement with 
         you to ensure that the investment you made in software 
         application development provides the best value for money,
          accommodating version upgrades, market evolution, and customer
           demands for new features.
        </p>
      </section>
    </main>
  );
}

export default Softwaresecurity;
