// App.js
import React from 'react';
import { BrowserRouter, Routes,Route} from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from '../src/pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Clients from './pages/Clients';
import Careers from './pages/Careers';
import Contact from './pages/Contact';
import Team from './pages/Team';
import Softwaredevelopment from './pages/Softwaredevelopment';
import Devops from './pages/Devops';
import Multicloud from './pages/Multicloud';
import Softwaresecurity from './pages/Softwaresecurity';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Program from './pages/Program';
import Teachnology from './pages/Teachnology';
import Scrollbar from './components/Scrollbar';
import Errorpage from './pages/Errorpage';

import '../src/styles/blog.css'
import '../src/styles/PrivacyPolicy.css'
import '../src/styles/style.css'
import '../src/styles/team.css'

const App = () => {
  return (

          <BrowserRouter>
        <Header/>
          <Routes>

            <Route exact path="/" element={<Home/>}/>
            <Route path="/About" element={<About />}/>
            <Route path="/Services" element={<Services />}/>
            <Route path="/Clients" element={<Clients />}/>
            <Route path="/Careers" element={<Careers />}/>
            <Route path="/Contact" element={<Contact />}/>
            <Route path="/Team" element={<Team />}/>
            <Route path="/Softwaredevelopment" element={<Softwaredevelopment />}/>
            <Route path="/Devops" element={<Devops />}/>
            <Route path="/Multicloud" element={<Multicloud />}/>
            <Route path="/Softwaresecurity" element={<Softwaresecurity />}/>
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
            <Route path="/Program" element={<Program/>}/>
            <Route path="/Teachnology" element={<Teachnology/>}/>
            <Route path="/Scrollbar" element={<Scrollbar/>}/>
            <Route path="*" element={<Errorpage />} />
          
            {/* Add more routes for other pages as needed */}
          </Routes>
          <Scrollbar/> 
          <Footer/>
         </BrowserRouter> 
  );
};
export default App;
