import React, { useEffect, useState } from 'react';
import "../styles/style.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faCogs, faBriefcase, faEnvelope, faBlog } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  

  useEffect(() => {
    const menuToggle = document.getElementById("menu-toggle");
    const menuLinks = document.querySelectorAll(".navbar ul li a");

    menuLinks.forEach(link => {
      link.addEventListener("click", () => {
        if (menuToggle.checked) {
          menuToggle.checked = false;
          setOpenMenu(false);
        }
      });
    });

    return () => {
      menuLinks.forEach(link => {
        link.removeEventListener("click", () => {
          if (menuToggle.checked) {
            menuToggle.checked = false;
            setOpenMenu(false);
          }
        });
      });
    };
  }, []);

  return (
    <header className="header">
      <div className="navbar">
        <div className="company-name">
        <Link to="/">
  <img className="logo" src ="https://storage.googleapis.com/dsoassets/assets/logon.webp" alt="DevSecOps Software" />
 
</Link>
        </div>
        <input type="checkbox" id="menu-toggle" className="menu-toggle" />
        <label htmlFor="menu-toggle" className="menu-icon" onClick={() => setOpenMenu(!openMenu)}>&#9776;</label>
        <ul className={openMenu ? "open" : ""}>
          <li>
            <Link to="/" onClick={() => setOpenMenu(false)}>
              <FontAwesomeIcon icon={faHome} /> Home
            </Link>
          </li>
          <li>
            <Link to="/About" onClick={() => setOpenMenu(false)}>
              <FontAwesomeIcon icon={faInfoCircle} /> About
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={() => setOpenMenu(false)}>
              <FontAwesomeIcon icon={faCogs} /> Services
            </Link>
          </li>
          <li>
            <Link to="/careers" onClick={() => setOpenMenu(false)}>
              <FontAwesomeIcon icon={faBriefcase} /> Careers
            </Link>
          </li>
          <li>
            <Link to="/Contact" onClick={() => setOpenMenu(false)}>
              <FontAwesomeIcon icon={faEnvelope} /> Contact
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
