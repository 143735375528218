// ContactSection.js
import React from 'react';
import '../styles/style.css';
import { useEffect } from 'react';

const ContactSection = () => { useEffect(() => {
  window.scrollTo(0, 0); 
}, []);
  return (
    <section className="bg-light">
      <div className="container1 py-4">
        <div className="contact-content">
          <div className="contact-header">
            <div className="our-contact">
              <h1 className="text-center mt-5 display-3 fw-bold">Contact With<span className="theme-text"> Us</span></h1>
              <hr className="mx-auto mb-5 w-25" />
            </div>
            <div style={{ paddingBottom: '30px', textAlign: 'center' }}></div>

            <h3>Have a query? We're here to provide answers!</h3>
            <br />
            <p>
              DevSecOps Softwares Pvt.Ltd aims to deliver top-class solutions and services expertise by developing, deploying, and handing over effective solutions that propel the growth of clients' businesses.
            </p>
            <img src="https://storage.googleapis.com/dsoassets/assets/banner.jpg" alt="Banner Image" />
            <div className="contact-info">
              <div className="icon">
                <i className='bx bx-news'></i>
              </div>
              <div style={{ paddingBottom: '80px', textAlign: 'center' }}></div>
              <ul style={{ fontSize: '18px' }}>
                <li className="h5">Connect with us at:</li>
                <a href="mailto:info@devsecopssoft.com">info@devsecopssoft.com</a>
              </ul>
            </div>
          </div>
          <div className="contact-img">
            <div style={{ paddingBottom: '80px', textAlign: 'center' }}></div>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdNoBwR3LNAU_-o1bDgO1rsGdzQyug2u0HKHp-_84-V9shCzQ/viewform?embedded=true"
              width="640"
              height="856"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            >
              Loading…
            </iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
