import React from "react";

import styled, { keyframes, css } from "styled-components";
const storagePath = 'https://storage.googleapis.com/dsoassets/assets/';

<img src={`${storagePath}mr.Munnalalverma.jpg`} alt=""/>

function App() {
  const row1 = [
    { src: `${storagePath}AWS_50.webp`, alt: "AWS" },
    { src: `${storagePath}gcp-removebg-preview%20(1).webp`, alt: "Google Cloud Platform" },
    { src: `${storagePath}Azure.webp`, alt: "Azure" },
    { src: `${storagePath}Jenkins_50.webp`, alt: "Jenkins" },
    { src: `${storagePath}Ansible.webp`, alt: "Ansible" },
    { src: `${storagePath}Kubernete.webp`, alt: "Kubernetes" },
    { src: `${storagePath}Terraform.webp`, alt: "Terraform" },
    { src: `${storagePath}docker.webp`, alt: "Docker" },
    { src: `${storagePath}helm.webp`, alt: "Helm" },
    { src: `${storagePath}argo.webp`, alt: "Argo" },
    { src: `${storagePath}Oracle_50.webp`, alt: "Oracle" },
    { src: `${storagePath}wiz.webp`, alt: "Wiz" },
    { src: `${storagePath}gitbucket.webp`, alt: "GitBucket" },
    { src: `${storagePath}GitLab.webp`, alt: "GitLab" },
    { src: `${storagePath}Istio.webp`, alt: "Istio" },
    { src: `${storagePath}Github.webp`, alt: "Istio" }
  ];

  return (
    <AppContainer>
      <Wrapper>
        <div className="our-teach">
          <h1 className="text-center mt-5 display-3 fw-bold">Technology Partners<span className="theme-text"> </span></h1>
          <hr className="mx-auto mb-5 w-25" />
        </div>
        <div style={{ paddingBottom: '30px', textAlign: 'center' }}></div>
        <Marquee>
          <MarqueeGroup className="fiStVV">
            {row1.map((el, index) => (
              <ImageGroup key={index}>
                <Image  src={el.src} alt={el.alt}  />
              </ImageGroup>
            ))}
          </MarqueeGroup>
          <MarqueeGroup className="fiStVV">
            {row1.map((el, index) => (
              <ImageGroup key={index}>
                <Image  src={el.src} alt={el.alt}  />
              </ImageGroup>
            ))}
          </MarqueeGroup>
        </Marquee>
      </Wrapper>
    </AppContainer>
  );
}

export default App;

const AppContainer = styled.div`
  width: 100vw;
  height: 40vh;
  color: #000000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #02203c;
`;

const Note = styled.div`
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 40px;
  color: #7c8e9a;
`;

const Marquee = styled.div`
  display: flex;
  width: 3300px;
  overflow: hidden;
  user-select: none;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1)
    hsl(0 0% 0% / 1) 
    hsl(0 0% 0% / 0) 
  );

  @media (max-width: 768px) {
    width: 100%;
    // overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
`;

const scrollX = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    right: translateX(0%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 120%;
  animation: ${scrollX} 38s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}

  @media (max-width: 768px) {
    width: auto;
    scroll-snap-align: start;
  }
`;


const MarqueeGroup2 = styled.div`
  ${common}
  animation-direction: reverse;
  animation-delay: -3s;
`;

const ImageGroup = styled.div`
  display: flex; 
  align-items: center;
  width: 600px; /* Fixed width */
  height: 120px; /* Fixed height */
  margin: 7px; /* Adjust margin as needed */
  border-radius: 0.5rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  @media (max-width: 768px) {
    width: 150px;
    height: auto;
  }
`;

const Image = styled.img`
object-fit: contain;
width: 100%;
height: 100%;
border-radius: 0.5rem;
aspect-ratio: 16/9;
padding: 5px 20px;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
// background: transparent; 
`;

