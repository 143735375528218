import '../styles/style.css';
import React from 'react';
import { useEffect } from 'react';

function Multicloud() { useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []); 
  return (
    <main>
        {/* <div style={{ paddingBottom: '65px', textAlign: 'center' }}></div> */}
      <section className="headerio">
        <h1>Cloud Management </h1>
        </section>
      <section className="services-item">
        <h2>Cloud Management Understanding</h2>
        <br />
        <p>
        Cloud management is the organized management of cloud computing products 
        and services that operate in the cloud. It refers to the processes, 
        strategies, policies, and technology used to help control and maintain
         public and private cloud, hybrid cloud, or multicloud environments. 
        </p>
      </section>

      <section className="services-item">
        <h2>Why Choose DevSecOps Software for Cloud Management Services?</h2>
        <br />
        <p>
        Hybrid cloud models are mainstream, as business continuity mandates public cloud adoption and CIOs demand a single pane of glass to manage the IT infrastructure.
        
        Disparate deployments like IaaS, PaaS, and SaaS in the cloud, including application support, backup administration, DevOps, make diverse resource skillsets, a criticality.
        
        Besides the benefits of cloud migration, key cloud management services we offer are support, proactive management, security, DevOps, capacity planning and cost control.
        </p>
      </section>

      <section className="services-item">
        <h2>Cloud Management Services</h2>
        <br />
        <p>Our enterprise infrastructure services division drives a comprehensive public cloud engineering and support offering for Amazon (AWS), Azure, and Google Cloud Platform’s cloud management services.

We provide a one-stop hybrid-cloud remote managed service, and transformative cloud enablement services such as cloud application, migration, and analytics. We advise, implement, and manage public clouds.</p>
      </section>

      <section className="services-item">
        <h2>Our Cloud Management Services Enlists</h2>
        <br />
        <p>
        Infrastructure Management (IaaS),	
        Application Management (PaaS),	
        Service Governance,	
        Automation, DevOps & Cross Service support,	
        Security and Compliance.
        </p>
      </section>
      <section className="services-item">
        <h2>Enterprise Benefits of Cloud Management</h2>
        <br />
        <p>Supporting Agile delivery in line with rapid organization changes and transformation projects,
        Regular patching and automation implementation,Drive IT delivery excellence in deployed cloud
         services through our CISF (Continuous Service Improvement Framework),
    Continuous Advisory with Key Finding Recommendations on a monthly and quarterly
     basis including cost control alerts and 
   Service Governance through a single pane of glass,
    giving better visibility insights with analytic tools and dashboards</p>
      </section>
    </main>
  );
}

export default Multicloud;
