// HomePage.js
import React from 'react';
import Services from './Services';
import Clients from './Clients';
import  "../styles/style.css";
import Teachnology from './Teachnology';
import { useEffect } from 'react';

const Home = () => {  useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []);
    return (
        <main>
        <div className="background-image">
          <div className="background-overlay"></div>
          <div className="overlay">
            <h2 style={{ color: 'aliceblue' }}>Welcome To </h2>
            <h2 className="subtitle"><span>'DevSecOps Softwares'</span></h2>
            <h3 data-content='"Innovation Today For Securing Tomorrow"' style={{ color: '#FFF', fontSize: '30px' }}></h3>
          </div>
        </div>
        <Services></Services>
        <Clients></Clients>
       <Teachnology></Teachnology>
        </main>
      );
    };
export default Home;
