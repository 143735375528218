import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import "../styles/style.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useEffect } from 'react';

const ServicesSection = () => { useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []); 
  return (
    <main>
      <div style={{ paddingBottom: '10px', textAlign: 'center' }}></div>
      <section className="applec">
        <div className="our-earth">
          <h1 className="text-center mt-5 display-3 fw-bold">Our<span className="theme-text"> Services</span></h1>
          <hr className="mx-auto mb-5 w-25" />
        </div>
        <div className="roc">
          {/* Top Left Card */}
          <div className="colc">
            {/* Use Link component instead of anchor tag */}
            <Link className="service-link" to="/Softwaredevelopment">
              <div className="carc">
                <div className="icon-wrapper">
                  <i className="fas fa-laptop"></i>
                </div>
                <h3>Software Development</h3>
                <p>
                  Software development services are quite a part of the modern fashion to build an application or website.
                  It fulfills different customer and organization level purposes. It comprises several phases: Scheduling,
                  Examination, Design, Development & enactment, stapling, Supervision, etc.
                </p>
              </div>
            </Link>
          </div>
          {/* Top Right Card */}
          <div className="colc">
            {/* Use Link component instead of anchor tag */}
            <Link className="service-link" to="/devops">
              <div className="carc">
                <div className="icon-wrapper">
                  <i className="fas fa-infinity"></i>
                </div>
                <h3>DevOps</h3>
                <p>
                An undaunted combination of Development and Operation, DevOps is a compound Software Development Method that
                 significantly highlights the responsibilities of a software developer along with other IT professionals.
                  , development and operations teams work together across the entire.
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div className="roc">
          {/* Bottom Left Card */}
          <div className="colc">
            {/* Use Link component instead of anchor tag */}
            <Link className="service-link" to="/softwaresecurity">
              <div className="carc">
                <div className="icon-wrapper">
                  <i className="fas fa-shield"></i>
                </div>
                <h3>Software Security</h3>
                <p>
                Software security refers to building security into the design, the code, and the deployment phases. With
              With this framework, you can fortify your entire DevOps pipeline. Close the conventional gaps between security
                  and IT while guaranteeing safe and quick delivery of code with our security testing services.
                </p>
              </div>
            </Link>
          </div>
          {/* Bottom Right Card */}
          <div className="colc">
            {/* Use Link component instead of anchor tag */}
            <Link className="service-link" to="/multicloud">
              <div className="carc">
                <div className="icon-wrapper">
                  <i className="fas fa-cloud"></i>
                </div>
                <h3>Cloud Management</h3>
                <p>
                Cloud Management entails overseeing and optimizing the utilization of one or more private or public clouds. Typically, organizations utilize a cloud management platform for overseeing cloud usage. It enables IT managers to transfer workloads across various clouds and regulate the expenditure on cloud resources.
                </p>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ServicesSection;
