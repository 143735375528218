import React, { useEffect } from 'react';
import '../styles/style.css';
import Team from './Team';

const AboutSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const storagePath = 'https://storage.googleapis.com/dsoassets/assets/';

  return (
    <main>
      <section className="about">
        <div className="our-about">
          <h1 className="text-center mt-5 display-3 fw-bold">About<span className="theme-text"> Us</span></h1>
        </div>
        <p>Welcome to DevSecOps Softwares Private Limited </p>
        <div className="about-info">
          <p>
            DevSecOps Softwares Private Limited, your partner in Technology, Innovation and Excellence with a deep-rooted passion for Software Development, DevOps, Multi-Cloud Management and Software Security. We empower Businesses to thrive in the Digital landscape.
          </p>
        </div>
      </section>
      <div className="mvv-contianer">
        <div className="mvv-block">
          <div className="image">
          <img src={`${storagePath}mission.jpg`} alt=""/>
          </div>
          <div className="content">
            <h5>Our Mission</h5>
            <br/>
            <p>
              "At DevSecOps Innovations, our goal is to lead the way in secure software development, multi-cloud solutions, and DevOps methodologies. We are committed to delivering cutting-edge technology services with an unwavering focus on security. Through innovation, collaboration, and client-centric solutions, we empower businesses to thrive in the digital landscape. Our goal is to be a trusted partner, setting industry benchmarks in excellence, and ensuring the highest standard of security across all facets of technology."
            </p>
          </div>
        </div>
        <div className="mvv-block">
          <div className="image">
          <img src={`${storagePath}integrity.jpg`} alt=""/>
          </div>
          <div className="content">
            <h5>Innovation</h5>
            <br/>
            <p>
              "Revolutionize your digital landscape with our cutting-edge IT solutions. Harness the power of bespoke software, cloud optimization, and cybersecurity expertise tailored to your business. Elevate performance, streamline operations, and lead the charge in the continuously evolving technology landscape. Our innovative IT solutions redefine possibilities, driving efficiency and success. Embrace the future with a partner committed to pushing boundaries and crafting unique, game-changing solutions for your company's digital journey."
            </p>
          </div>
        </div>
        <div className="mvv-block">
          <div className="image">
          <img src={`${storagePath}security-speed-stability-the-three-ss-of-wordpress.jpg`} alt=""/>
          </div>
          <div className="content">
            <h5>We ensure speed stability and security</h5>
            <br/>
            <p>
              "Our sturdy system promises the triumvirate of velocity, steadfastness, and safety, ensuring a flawless user experience. With optimized processes and cutting-edge technology, we prioritize delivering swift performance while maintaining the utmost stability. Security is paramount, with stringent measures in place to safeguard content and user data, providing a trustworthy environment for all interactions."
            </p>
          </div>
        </div>
        <div className="mvv-block">
          <div className="image">
          <img src={`${storagePath}innovation.jpg`} alt=""/>
          </div>
          <div className="content">
            <h5>Integrity and ethics</h5>
            <br/>
            <p>
              "Our commitment to integrity and ethics forms the cornerstone of our operations. Upholding the highest standards, we prioritize transparency, honesty, and fairness in all endeavors. Ethical conduct guides every decision, fostering a culture of trust and accountability. We are dedicated to ensuring that our actions align with principles that promote integrity, creating a foundation for sustainable relationships and responsible business practices."
            </p>
          </div>
        </div>
      </div>
      <Team></Team>
    </main>
  );
};

export default AboutSection;
