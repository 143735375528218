import React, { useEffect, useState } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import '../styles/Slideshow.css'; 
import Arya from '../assets/AR.jpg';


const storagePath = 'https://storage.googleapis.com/dsoassets/assets/';

const Slideshow = () => {
  useEffect(() => {
    const swiper = new Swiper('.mySwiper', {
      slidesPerView: 'auto', // Display as many slides as possible in the available space
      spaceBetween: 20, // Adjust spacing between slides as needed
      loop: true,
      loopFillGroupWithBlank: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    // Automatically transition slides every 3 seconds
    const interval = setInterval(() => {
      if (swiper) {
        swiper.slideNext(); // Advance to the next slide
      }
    }, 2500);// Change the interval duration from 3000 (3 seconds) to 5000 (5 seconds)

    return () => clearInterval(interval);
  }, []);

  return (
    <section>
      <div className="our-team">
        <h1 className="text-center mt-5 display-3 fw-bold">Our <span className="theme-text">Team</span></h1>
        <hr className="mx-auto mb-5 w-25" />
      </div>
      <div className="swiper mySwiper container">
        <div className="swiper-wrapper content">
          <div className="swiper-slide card">
            <div className="content">
              <div className="imgBx">
              <img src={`${storagePath}mr.Munnalalverma.jpg`} alt=""/>
              </div>
              <div className="contentBx">
                <h4>Mr. Munna Lal Verma</h4>
                <br/>
                <h5>Managing director</h5>
              </div>
              <div className="sci">
                <a href="mailto:hr@devsecopssoft.com" target="_blank"><i className="fas fa-envelope" aria-hidden="true"></i></a>
                  <a><i className="fab fa-github" aria-hidden="true"></i></a>
                  <a><i className="fa-brands fa-linkedin"></i></a>
              </div>
            </div>
          </div>
          <div className="swiper-slide card">
            <div className="content">
              <div className="imgBx">
              <img src={`${storagePath}AkashVerma.png`} alt=""/>
              </div>
              <div className="contentBx">
                <h4>Mr. Akash Verma</h4>
                <br/>
                <h5>Founder & CEO</h5>
              </div>
              <div className="sci">
              <a href="mailto:akash.verma@devsecopssoft.com" target="_blank"><i className="fas fa-envelope" aria-hidden="true"></i></a>
                  <a href="https://github.com/akash-devsecops" target="_blank"><i className="fab fa-github" aria-hidden="true"></i></a>
                  <a href="https://www.linkedin.com/in/akashv01/" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
              </div>
            </div>
          </div>
          <div className="swiper-slide card">
            <div className="content">
              <div className="imgBx">
              <img src={`${storagePath}MeghaVerma.jpg`} alt=""/>
              </div>
              <div className="contentBx">
                <h4>Mrs. Megha Verma</h4>
                <br/>
                <h5>Hr Manager</h5>
              </div>
              <div className="sci">
              <a href="mailto:hr@devsecopssoft.com" target="_blank"><i className="fas fa-envelope" aria-hidden="true"></i></a>
                <a><i className="fab fa-github" aria-hidden="true"></i></a>
                <a><i className="fa-brands fa-linkedin"></i></a>
              </div>
            </div>
          </div>
          <div className="swiper-slide card">
            <div className="content">
              <div className="imgBx">
              <img src={Arya} alt=""/>
              </div>
              <div className="contentBx">
                <h4>Mr. Arya Kumar Arya</h4>
                <br/>
                <h5>DevOps/Cloud Engineer</h5>
              </div>
              <div className="sci">
              <a href="mailto:arya.kumar@devsecopssoft.com" target="_blank"><i className="fas fa-envelope" aria-hidden="true"></i></a>
              <a href="https://github.com/arya-devsecops" target="_blank"><i className="fab fa-github" aria-hidden="true"></i></a>
              <a href="https://www.linkedin.com/in/arya-kumar-arya-a8b198297/" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
              </div>
            </div>
          </div>
          <div className="swiper-slide card">
            <div className="content">
              <div className="imgBx">
              <img src={`${storagePath}harshSaini.jpg`} alt=""/>
              </div>
              <div className="contentBx">
                <h4>Mr. Harsh Kumar Saini </h4>
                <br/>
                <h5>Devops Engineer</h5>
              </div>
              <div className="sci">
              <a href="mailto:harsh.kumar@devsecopssoft.com" target="_blank"><i className="fas fa-envelope" aria-hidden="true"></i></a>
              <a href="https://github.com/harsh-devsecops" target="_blank"><i className="fab fa-github" aria-hidden="true"></i></a>
              <a href="https://www.linkedin.com/in/harsh-saini-62726b155/" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
              </div>
            </div>
          </div>
          <div className="swiper-slide card">
            <div className="content">
              <div className="imgBx">
              <img src={`${storagePath}priyanshuVerma.jpg`} alt=""/>
              </div>
              <div className="contentBx">
                <h4>Priyanshu verma </h4>
                <br/>
                <h5>Software Engineer </h5>
              </div>
              <div className="sci">
              <a href="mailto:priyanshu.verma@devsecopssoft.com" target="_blank"><i className="fas fa-envelope" aria-hidden="true"></i></a>
              <a href="https://github.com/priyanshu-devsecops" target="_blank"><i className="fab fa-github" aria-hidden="true"></i></a>
              <a href="https://www.linkedin.com/in/priyanshu-verma94/" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
              </div>
            </div>
          </div>
          {/* Repeat similar structure for other slides */}
          <div className="swiper-slide card">
            <div className="content">
              <div className="imgBx">
              <img src={`${storagePath}AryanPandey.jpg`} alt=""/>
              </div>
              <div className="contentBx">
                <h4>Aryan Pandey </h4>
                <br/>
                <h5>Software Engineer </h5>
              </div>
              <div className="sci">
              <a href="mailto:aryan.pandey@devsecopssoft.com" target="_blank"><i className="fas fa-envelope" aria-hidden="true"></i></a>
                  <a href="https://github.com/aryan-devsecops" target="_blank"><i className="fab fa-github" aria-hidden="true"></i></a>
                  <a href="https://www.linkedin.com/in/aryan-pandey-0b8655249/" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
              </div>
            </div>
          </div>
          {/* Repeat similar structure for other slides */}
          <div className="swiper-slide card">
            <div className="content">
              <div className="imgBx">
              <img src={`${storagePath}sivam.jpg`} alt=""/>
              </div>
              <div className="contentBx">
                <h4>Shivam Sah</h4>
                <br/>
                <h5>Software Engineer </h5>
              </div>
              <div className="sci">
              <a href="shivam.sah@devsecopssoft.com" target="_blank"><i className="fas fa-envelope" aria-hidden="true"></i></a>
              <a href="https://github.com/shivam-devsecops" target="_blank"><i className="fab fa-github" aria-hidden="true"></i></a>
              <a href="https://linkedin.com/in/shivam3112" target="_blank"><i className="fa-brands fa-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};
export default Slideshow;