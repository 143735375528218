import React, { useEffect, useState } from 'react';
import "../styles/style.css";

const Footer = () => {
  const [visitCount, setVisitCount] = useState(0);

  useEffect(() =>{
    // Retrieve the page view count from localStorage
    const pageView = localStorage.getItem("page_view");

    // Convert the page view count to a number
    const pageViewNumber = pageView ? parseInt(pageView) : 0;

    // Update the state with the retrieved page view count
    setVisitCount(pageViewNumber);

    // Increment the page view count by 1 and update localStorage
    const incrementPageView = () => {
      localStorage.setItem("page_view", String(pageViewNumber + 1));
    };

    // Listen for the pagehide event and increment the page view count
    window.addEventListener("pagehide", incrementPageView);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("pagehide", incrementPageView);
    };
  }, []);

  const handleReset = () => {
    // Reset the page view count to 1
    setVisitCount(1);

    // Update the localStorage with the new page view count
    localStorage.setItem("page_view", "1");
  };
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="footer-col">
              <h4>OUR SERVICES</h4>
              <ul>
                <li><a href="/Softwaredevelopment">Software Development</a></li>
                <li><a href="/devops">DevOps</a></li>
                <li><a href="/multicloud">Cloud Management</a></li>
                <li><a href="/softwaresecurity">Software Security</a></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>USEFUL LINKS</h4>
              <ul>
                <li><a href="/about">About</a></li>
                <li><a href="/PrivacyPolicy">Privacy Policy</a></li>
                <li><a href="/Careers">Current Openings</a></li>
                <li><a href="/contact">Help</a></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>CONTACT</h4>
              <ul>
                <li>
                 <a> <p>US address: 8940 Kennebec Crossing Drive, Angier , NC, 27501</p>
                  <p>India Address: 1505, Tower-7, Shri Radha Sky Garden, Sector-16B Greater Noida West-201009, UP, India</p></a>
                </li>
                <li>
                  <a href="mailto:info@devsecopssoft.com">info@devsecopssoft.com</a>
                </li>
                <li><a href="tel:+91 9761981880" target="_blank" rel="noopener noreferrer">+91 9761981880</a></li>
                <li><a href="tel:+1(919)-353-4257" target="_blank" rel="noopener noreferrer">+1(919)-353-4257</a></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>follow us</h4>
              <div className="social-links">
                <a href="https://www.facebook.com/profile.php?id=61554912215605" target="_blank" aria-label="Visit our Facebook profile">
                  <i className="fab fa-facebook-f" aria-hidden="true"></i>
                  <span className="sr-only">Visit our Facebook profile</span>
                </a>
                <a href="https://www.youtube.com/channel/UC7f4wLdoUYM293kNCY66ghA" target="_blank" aria-label="Visit our YouTube channel">
                  <i className="fab fa-youtube" aria-hidden="true"></i>
                  <span className="sr-only">Visit our YouTube channel</span>
                </a>
                <a href="https://www.instagram.com/devsecops2023/" target="_blank" aria-label="Visit our Instagram profile">
                  <i className="fab fa-instagram" aria-hidden="true"></i>
                  <span className="sr-only">Visit our Instagram profile</span>
                </a>
                <a href="https://www.linkedin.com/company/devsecops-softwares-pvt-ltd/" target="_blank" aria-label="Visit our LinkedIn profile">
                  <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                  <span className="sr-only">Visit our LinkedIn profile</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div style={{ backgroundColor: '#24262b', color: '#ffffff', textAlign: 'center', padding: '10px' }}>
  © 2024 Copyright: <a style={{ color: 'white' }} href="/">DevSecOps Softwares Pvt.Ltd</a>
</div>
    </>
  );
};
export default Footer;
