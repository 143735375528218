import React from 'react';
import  "../styles/PrivacyPolicy.css";
import { useEffect } from 'react';


const DevOpsProgram = () => { useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []); 
  return (
    <div className="app">
       <head>
        <title>DevOps Program</title>
        <meta name="description" content="Welcome to Our DevOps Program. Join us to learn about assessment and planning, continuous integration, continuous deployment, and more." />
      </head>
        <div style={{ paddingBottom: '75px', textAlign: 'center' }}></div>
      <h1 className="app__title">Welcome to Our DevOps Program</h1>
      <div className="devops-program">
        <h2 className="devops-program__title">DevOps Program Outline</h2>
        <ol className="devops-program__list">
          <li className="devops-program__item">
            <h3 className="devops-program__subtitle">Assessment and Planning:</h3>
            <ul className="devops-program__sublist">
              <li>Assess the current state of software development and operations processes, identifying areas for improvement and opportunities for automation.</li>
              <li>Define the goals and objectives of the DevOps program, such as reducing deployment time, increasing release frequency, improving software quality, and enhancing collaboration between teams.</li>
              <li>Develop a roadmap outlining the steps and milestones for implementing DevOps practices and tools.</li>
            </ul>
          </li>
          {/* Continue listing the other points */}
        </ol>
      </div>
    </div>
  );
};
export default DevOpsProgram;