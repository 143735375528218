import React from 'react';
import '../styles/style.css';
import { useEffect } from 'react';

const CareersSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []); 

 
  return (
  
    <section className="careers">
      <div className="container2">
        <div className="our-careers">
          <h1 className="text-center mt-5 display-3 fw-bold">Careers<span className="theme-text"> </span></h1>
          <hr className="mx-auto mb-5 w-25" />
        </div>
        <p><strong>Join our team of talented professionals who are passionate about technology, innovation, and excellence.</strong></p>
        <div style={{ paddingBottom: '30px', textAlign: 'center' }}></div>
        <div className="job-listings">

        <div className="job">
  <h2>Summer Internship Program</h2>
  <p><strong>Last date to apply :</strong>  20th May 2024</p>
  <p><strong>Location:</strong> Remote</p>
  <div className="job-description">
    <p>Our summer internship program offers a unique opportunity for talented and ambitious individuals to dive into the world of technology, work on real-world projects, and learn from industry experts.</p>
    <p>Whether you're passionate about coding, fascinated by Clouds or eager to explore the world of DevOps, we have something for you. At DevSecOps Softwares, interns are not just observers; they are integral members of our team.</p>
    <p>Join us for a rewarding experience where you'll develop valuable skills, build meaningful connections, and set the stage for a successful career in the tech industry.</p>
  </div>
  <a href="https://forms.gle/efZt3ixdTrwBfa766" className="apply-btn" target="_blank" rel="noopener noreferrer">Apply Now</a>
</div>




          <div className="job">
            <h2>Software Intern</h2>
            <p>Greater Noida, India</p>
            <p>We are looking for a motivated Software Engineer Intern to join our team! As a Software Engineer Intern, you will collaborate with our senior leaders on assignments.</p>
            {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSfyDp9G3M00tZwIwIBjE6Vq2BiYCyd0CNHDr27QNETv_wm56w/viewform?usp=sf_link" className="apply-btn" target="_blank" rel="noopener noreferrer">Apply Now</a> */}
            <a href="#" className="apply-btn" rel="noopener noreferrer">Apply Now</a>

          </div>
          <div className="job">
            <h2>Devops Intern</h2>
            <p>Greater Noida, India</p>
            <p>Join our DevOps team and contribute to the automation and optimization of our development processes.</p>
            {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLScilBZ3__MdW6PMCbtaot4HjiUoXb2unlwKnJuwaVJXU6FGJA/viewform?usp=sf_link" className="apply-btn" target="_blank" rel="noopener noreferrer">Apply Now</a> */}
            <a href="#" className="apply-btn"  rel="noopener noreferrer">Apply Now</a>
          </div>
          {/* Add more job listings as needed */}
          <div className="job">
            <h2>Devops Expert</h2>
            <p>Greater Noida, India</p>
            <p>A DevOps Engineer Expert has an essential role in integrating the project functions and resources across the product life cycle, right from planning, building, testing, and deployment to support.</p>
            {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSfuLn8PZjnKEcbdp9YmYfcCQgt_8MQmtkYSXAr-AtA25nPQ1Q/viewform?usp=sf_link" className="apply-btn" target="_blank" rel="noopener noreferrer">Apply Now</a> */}
           <a href="#" className="apply-btn"  rel="noopener noreferrer">Apply Now</a> 
          </div>

          <div className="job">
            <h2>Python Developer</h2>
            <p>Greater Noida, India</p>
            <p>Bachelor's degree in Computer Science, Software Engineering or a related field.
              In-depth understanding of the Python software development stacks, ecosystems, frameworks and tools such as Numpy, Scipy, Pandas, Dask, spaCy, NLTK, sci-kit-learn and PyTorch.</p>
            <a href="#" className="apply-btn" rel="noopener noreferrer">Apply Now</a>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: '100px', textAlign: 'center' }}></div>
    </section>
   
  );
};

export default CareersSection;