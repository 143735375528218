// PrivacyPolicy.js

import React from 'react';
import { useEffect } from 'react';

const PrivacyPolicy = () => { useEffect(() => {
  window.scrollTo(0, 0); // Scroll to the top of the page
}, []); 
  return (
   
    <div className="privacy-policy-container">
         {/* <div style={{ paddingBottom: '75px', textAlign: 'center' }}></div> */}
      <h1 className="text-center">Privacy Policy</h1>
      <p><strong>Effective Date: 08-02-2024</strong></p>
      <h3>Your privacy is important to us</h3>
      <p>DevSecOps Softwares Pvt.Ltd  is located at:</p>
      <address>
      1505, Tower-7, Shri Radha Sky Garden,<br /> Sector-16B Greater Noida West-201009, <br />UP, India<br />
       
      </address>
      <p>
        It is Devops's policy to respect your privacy regarding any information
        we may collect while operating our website. This Privacy Policy applies
        to <a href="https://devsecopssoft.com/">devsecoft.com/</a> (hereinafter,
        "us", "we", or "devsecopssoft.com"). We respect your privacy and are
        committed to protecting personally identifiable information you may
        provide us through the Website. We have adopted this privacy policy
        ("Privacy Policy") to explain what information may be collected on our
        Website, how we use this information, and under what circumstances we
        may disclose the information to third parties. This Privacy Policy
        applies only to information we collect through the Website and does not
        apply to our collection of information from other sources.
      </p>
      <p>
        This Privacy Policy, together with the Terms of Service posted on our
        Website, set forth the general rules and policies governing your use of
        our Website. Depending on your activities when visiting our Website, you
        may be required to agree to additional terms of service.
      </p>
      <h2 id="table-of-contents">Contents</h2>
      <p>Click below to jump to any section of this privacy policy</p>
      <ol type="1">
        <li>
          <a href="#PII"><strong>Personally-Identifying Information</strong></a>
        </li>
        <li>
          <a href="#Security"><strong>Security</strong></a>
        </li>
        <li>
          <a href="#ExternalLinks"><strong>Links To External Sites</strong></a>
        </li>
        <li>
          <a href="#Ecommerce"><strong>E-commerce</strong></a>
        </li>
        <li>
          <a href="#Changes"><strong>Privacy Policy Changes</strong></a>
        </li>
        <li>
          <a href="#Credit"><strong>Contact Information & Credit</strong></a>
        </li>
      </ol>
      <h2 id="PII">1. Personally-Identifying Information</h2>
      <p><a href="#table-of-contents">Back to table of contents</a></p>
      <h2 id="Security">2. Security</h2>
      <p>The security.</p>
      <p><a href="#table-of-contents">Back to table of contents</a></p>
      <h2 id="ExternalLinks">3. Links To External Sites</h2>
      <p>
        Our Service may contain links to external sites that are not operated by
        us. If youce of every site you visit.
      </p>
      <p>We have no control over, and assume nervices.</p>
      <p><a href="#table-of-contents">Back to table of contents</a></p>
      <h2 id="Ecommerce">4. E-commerce</h2>
      <p>
        Those who engage in transactions with Devops – by purchasing Devops's
        services or products, are asked to provide additional information,
        including as necessary the personal and them from engaging in certain
        website-related activities.
      </p>
      <p><a href="#table-of-contents">Back to table of contents</a></p>
      <h2 id="Changes">5. Privacy Policy Changes</h2>
      <p>Althou such change.</p>
      <p><a href="#table-of-contents">Back to table of contents</a></p>
      <h2 id="Credit">6. Contact Information & Credit</h2>
      <p>
        This privacy policy was created at{' '}
        <a
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          privacyterms.io privacy policy generator
        </a>
        . If you have any questions about our Privacy Policy, please contact us
        via <a href="mailto:info@devsecopssoft.com">email</a> or <a href="tel:">phone</a>.
      </p>
      <p><a href="#table-of-contents">Back to table of contents</a></p>
    </div>
  );
};

export default PrivacyPolicy;
